import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { DrawerDialog } from 'ui/drawer-dialog'

import { DestinationModal } from 'components/destination-modal'
import { CategoryItem } from 'components/category-item'

import { useToggle } from 'lib/hooks/useToggle'
import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'
import { buildPath } from 'lib/utils'
import { PageCategory } from 'lib/@Types'

import { SEARCH_ROUTE } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

type CategoryActivityTilesProps = {
  trackEvent: TrackEventType
  categories: PageCategory[]
}

const INITIAL_MOBILE_SHOWN = 7
const INITIAL_TABLET_SHOWN = 5
const INITIAL_DESKTOP_SHOWN = 8

const CategoryActivityTiles = ({ categories, trackEvent }: CategoryActivityTilesProps) => {
  const { isMobileView, isTabletView } = useGlobalContext()
  const { t } = useTranslation('common')

  const [isOpen, { onOpen, onClose }] = useToggle()
  const [isDestModalOpen, { onOpen: onOpenDestModal, onClose: onCloseDestModal }] = useToggle()
  const router = useRouter()

  const [selectedCategory, setSelectedCategory] = useState<PageCategory>()

  const displayAllCategory = useMemo(() => {
    if (isMobileView) {
      return categories.length > INITIAL_MOBILE_SHOWN
    }

    if (isTabletView) {
      return categories.length > INITIAL_TABLET_SHOWN
    }

    return categories.length > INITIAL_DESKTOP_SHOWN
  }, [categories, isMobileView, isTabletView])

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        attributeId: EVENTS.ACTIVITY_VIEW_ALL,
        attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
        eventType: EVENTS.TYPE.EXPOSURE,
        attributeValue: {
          cards: categories.map((category, index) => ({
            categoryId: category.categoryId,
            cardIndex: index,
          })),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onSelect = (data: PageCategory, index: number, eventName: string) => () => {
    setSelectedCategory(data)

    trackEvent({
      attributeId: eventName,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: {
        selection: data.categoryId,
        index,
        selectionType: 'category',
      },
    })
    onOpenDestModal()
  }

  const onRedirectToGPLP = (isCountry?: boolean) => (id: string) => {
    const key = isCountry ? 'countryIds' : 'destinationIds'
    const gplpQuery = selectedCategory?.parentId
      ? { subcategories: selectedCategory.categoryId }
      : { categories: selectedCategory?.categoryId }

    router.push(
      buildPath(
        SEARCH_ROUTE,
        {},
        {
          [key]: id,
          ...gplpQuery,
        }
      )
    )
  }

  return (
    <div className={s.container}>
      <div className={s.categoryActivityTiles}>
        {categories.map((category, index) => (
          <CategoryItem
            key={category.categoryId}
            label={category.categoryShortLabel}
            iconName={category.categoryIcon}
            onClick={onSelect(category, index, EVENTS.CATEGORY_TILE)}
          />
        ))}
        {displayAllCategory && <CategoryItem label={t('t.all')} iconName="square-four" onClick={onOpen} />}
      </div>
      <DrawerDialog
        open={isOpen}
        onClose={onClose}
        onClickOutside={onClose}
        noBodyPadding
        asBottomSheet={isMobileView}
        variant={isMobileView ? 'auto' : 'large'}
        headerVariant={isMobileView ? 'default' : 'absolute'}
        header={t('t.exploreByCategories')}
        animation={isMobileView ? 'slideUp' : 'fade'}
      >
        <div className={s.categoriesGrid}>
          {categories.map((category, index) => (
            <CategoryItem
              key={category.categoryId}
              label={category.categoryShortLabel}
              iconName={category.categoryIcon}
              onClick={onSelect(category, index, EVENTS.CATEGORY_TILE)}
            />
          ))}
        </div>
      </DrawerDialog>
      <DestinationModal
        open={isDestModalOpen}
        closeModal={onCloseDestModal}
        trackEvent={trackEvent}
        selectedCategory={selectedCategory?.categoryId}
        shouldRedirectAfterSelect={false}
        onCountryChange={onRedirectToGPLP(true)}
        onDestinationChange={onRedirectToGPLP(false)}
      />
    </div>
  )
}

export { CategoryActivityTiles }
