/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react'
import cn from 'classnames'
import { Trans } from 'next-i18next'

import { Image } from 'ui/image'

import { ExposureTracker } from 'components/exposure-tracker'
import { Container } from 'components/container'
import { DynamicComponentProps } from 'components/dynamic-components/types'
import { CampaignBanner } from 'components/campaign-banner'

import useHeader from 'lib/hooks/useHeader'
import useDateCurrency from 'lib/hooks/useDateCurrency'

import { useGlobalContext } from 'lib/context/global-context'
import { MediaItem, PageCategory } from 'lib/@Types'

import { EVENTS } from 'lib/constants/events'

import { SearchBar } from './search-bar'
import { CategoryActivityTiles } from './category-activity-tiles'

import s from './styles.module.scss'

interface HeroProps extends DynamicComponentProps {
  trackEvent?: TrackEventType
  id: 'hero'
  categories: PageCategory[]
}

interface CampaignHeroImage extends MediaItem {
  is_campaign?: boolean
  is_mobile?: boolean
}

// const HERO_IMAGE_ROTATION = 6000
// const DELAY_HERO_IMAGE_LOAD = HERO_IMAGE_ROTATION - 2000

const Hero = ({ componentEventId, componentContent, categories, trackEvent, id }: HeroProps) => {
  const { isMobileView } = useGlobalContext()
  const { images } = componentContent

  const [activeHeroIndex] = React.useState(() => {
    return Math.floor(Math.random() * images.length)
  })

  // const initialIndexRef = useRef(activeHeroIndex)

  const { formatNumber } = useDateCurrency()

  const { setWayPoint: searchInputWayPoint } = useHeader({
    mode: 'searchVisibility',
    defaultValue: false,
  })

  const { setWayPoint: scrollStartWayPoint } = useHeader({
    mode: 'transparency',
    defaultValue: true,
  })

  // Commented out the code for image carousel for now
  // TODO: Yanto - Uncomment this once can identify how to improve the performance
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setActiveHeroIndex((curr) => (curr + 1) % images?.length)
  //   }, HERO_IMAGE_ROTATION)

  //   return () => clearTimeout(timeoutId)
  // }, [activeHeroIndex, images.length])

  // const getDelayLoadValue = (index: number) => {
  //   if (!initialIndexRef.current && initialIndexRef.current === index) {
  //     return undefined
  //   }

  //   const lastIndex = images.length - 1
  //   let nextIndex = index + 1
  //   if (index < initialIndexRef.current) {
  //     const incrementValue = lastIndex - initialIndexRef.current + index + 1
  //     nextIndex = incrementValue
  //   } else {
  //     nextIndex = index - initialIndexRef.current
  //   }

  //   return DELAY_HERO_IMAGE_LOAD * nextIndex
  // }

  const image = useMemo(() => {
    const image = images[activeHeroIndex]
    const { is_campaign: isSeasonalCampaignImage } = image || {}

    if (!isSeasonalCampaignImage) return image
    const seasonalImage = images.find((image: CampaignHeroImage) =>
      isMobileView ? image.is_mobile : !image.is_mobile
    )
    return { ...seasonalImage, sizes: {} }
  }, [images, activeHeroIndex, isMobileView])

  const isCampaignImage = image?.is_campaign

  return (
    <div id={id} className={cn(s.container)}>
      <div ref={scrollStartWayPoint} className={s.scrollStartWayPoint} />
      <div className={s.heroCover}>
        <div className={s.heroImage}>
          <Image
            src={image}
            layout="fill"
            className={cn({ [s.active]: true })}
            alt="hero image"
            lazyLoad={false}
            priority
            key={image?.url}
          />
          {/* {images.map((image: MediaItem, index: number) => {
          const lazyLoad = index !== initialIndexRef.current
          const delayLoad = getDelayLoadValue(index)

          return (
            <Image
              key={index}
              src={image}
              layout="fill"
              alt="hero image"
              lazyLoad={lazyLoad}
              delayLoad={delayLoad}
              className={cn({ [s.active]: activeHeroIndex === index })}
            />
          )
        })} */}
          <div className={cn(s.gradient, { [s.hideBottomGradient]: isCampaignImage })} />
        </div>

        <Container className={s.body}>
          <div className={s.searchInputWrapper}>
            <SearchBar trackEvent={trackEvent} />
          </div>
          <div className={s.headerSection}>
            {!isCampaignImage && (
              <h1 className={s.header}>
                <Trans
                  ns="common"
                  i18nKey="msg.bookNumOfActivitiesWorldwide"
                  values={{
                    num: formatNumber(200000),
                  }}
                  components={{
                    span: <span></span>,
                  }}
                />
              </h1>
            )}
          </div>
        </Container>
        <div ref={searchInputWayPoint} className={s.searchInputWayPoint} />

        <ExposureTracker
          onExposure={() => {
            trackEvent?.({
              attributeId: componentEventId,
              attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
              eventType: EVENTS.TYPE.EXPOSURE,
            })
          }}
        />
      </div>
      <CampaignBanner trackEvent={trackEvent} />
      <Container className={s.categoriesContainer}>
        <CategoryActivityTiles categories={categories} trackEvent={trackEvent!} />
      </Container>
    </div>
  )
}

export { Hero }
