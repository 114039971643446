import { useRef, useState } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import { ExposureTracker } from 'components/exposure-tracker'
import { Link } from 'components/link'
import { RichText } from 'components/rich-content/RichText'

import _useLayoutEffect from 'lib/hooks/useLayoutEffect'

import { useAppData } from 'lib/context/app-data-context'
import { getErrorInfo } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { CAMPAIGN_TYPE } from 'lib/constants'

import s from './styles.module.scss'

const PromoNotification = ({ position = 'bottom' }: { position?: 'top' | 'bottom' }) => {
  const { trackEvent, campaignData } = useAppData()
  const notificationRef = useRef<HTMLDivElement>(null)

  const [promoBannerVisible, setPromoBannerVisible] = useState(
    campaignData?.campaignType === CAMPAIGN_TYPE.UTM_CAMPAIGN &&
      (!!campaignData?.isActive || campaignData?.isPreviewMode)
  )

  _useLayoutEffect(() => {
    setPromoBannerVisible(
      campaignData?.campaignType === CAMPAIGN_TYPE.UTM_CAMPAIGN &&
        (!!campaignData?.isActive || campaignData?.isPreviewMode)
    )
  }, [campaignData?.isActive, campaignData?.campaignType])

  _useLayoutEffect(() => {
    const updateNotificationHeight = () => {
      if (notificationRef?.current && position === 'top') {
        const bannerHeight = notificationRef?.current?.clientHeight
        document.body.style.setProperty('--notification-height', bannerHeight + 'px')
      }
    }

    window?.addEventListener?.('resize', updateNotificationHeight)

    return () => window?.removeEventListener?.('resize', updateNotificationHeight)
  }, [position])

  _useLayoutEffect(() => {
    if (position !== 'top') return

    if (notificationRef?.current) {
      const { height } = notificationRef?.current?.getBoundingClientRect()
      document.body.style.setProperty('--notification-height', `${promoBannerVisible ? height : 0}px`)
    } else {
      document.body.style.setProperty('--notification-height', '0px')
    }
  }, [promoBannerVisible, position])

  if (!campaignData?.campaignId) return null

  const errorCode = getErrorInfo(campaignData)?.code
  const { isActive, content, isPreviewMode } = campaignData || {}

  if (errorCode || (!isActive && !isPreviewMode) || !content) {
    return null
  }

  const handleCloseNotification = () => {
    setPromoBannerVisible(false)

    trackEvent?.current?.({
      attributeId: EVENTS.MARKETING_BANNER_CLOSE,
      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
    })
  }

  const { title, description, link, button } = content

  return promoBannerVisible ? (
    <>
      <div className={cn(s.notification, s[`position-${position}`], 'notification')} ref={notificationRef}>
        <div className={s.notificationInner}>
          <div className={s.notificationInnerContent}>
            <div className={s.notificationText}>
              <div className={s.notificationHeader}>{title}</div>
              <RichText text={description} className={s.descriptionText} />

              {link && (
                <Link href={link.url || '#'} passHref>
                  <a
                    className={s.linkTnC}
                    target="_blank"
                    onClick={() =>
                      trackEvent?.current?.({
                        attributeId: EVENTS.MARKETING_BANNER_TNC,
                        attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                      })
                    }
                    aria-label={link.label}
                  >
                    {link.label}
                  </a>
                </Link>
              )}
            </div>

            {button && (
              <div className={s.buttonCtaContainer}>
                <Button
                  aria-label={button.label}
                  variant="primary"
                  size="small"
                  className={s.buttonCta}
                  fluid={false}
                  onClick={() => {
                    trackEvent?.current?.({
                      attributeId: EVENTS.MARKETING_BANNER,
                      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                    })
                    // Requirement is not defined for button click. Update in future once its defined.
                    window.open(button.url, '_blank')
                  }}
                >
                  <span>{button.label}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
        <button
          aria-label="Close banner"
          className={cn(s.closeCta, s.icon)}
          onClick={handleCloseNotification}
        >
          <Icon name="delete-x" size="semi-medium" />
        </button>
      </div>

      <ExposureTracker
        onExposure={() => {
          trackEvent?.current?.({
            attributeId: EVENTS.MARKETING_BANNER,
            attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
            eventType: EVENTS.TYPE.EXPOSURE,
          })
        }}
      />
    </>
  ) : null
}

export { PromoNotification }
